<template>
  <div class="nav_aside" :style="backgroundColor">
    <el-menu
      class="el_menu_vertical_demo"
      :background-color="bkColor"
      text-color="#fff"
      router
      :default-active="$route.meta.nav"
    >
      <div v-for="(item, index) in menuList" :key="index">
        <el-submenu v-if="item.children" :index="index + ''">
          <template slot="title">
            <i
              style="margin-right: 23px;"
              :class="item.icon"></i>
            <span>{{item.title}}</span>
          </template>
          <el-menu-item
            style="padding-left: 60px;"
            v-for="(items, index2) in item.children"
            :index="items.path"
            :key="index2">
            {{items.title}}
          </el-menu-item>
        </el-submenu>
        <el-menu-item
          v-else
          :index="item.path">
          <i
            style="margin-right: 23px;"
            :class="item.icon"></i>
          <span>{{item.title}}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    bkColor: {
      type: String,
      default: '#313131'
    },
    menuList: {
      type: Array
    }
  },
  computed: {
    backgroundColor () {
      return `backgroundColor: ${this.bkColor};`
    }
  },
  data () {
    return {
      activePath: '/taskhall'
    }
  }
}
</script>

<style lang="scss" scoped>
.nav_aside {
  width: 100%;
  height: 100%;
  .el-menu {
    border: none;
    .el-submenu {
      border: none;
      .el-menu-item {
        border: none;
        height: 56px;
        font-size: 14px;
      }
    }
  }
}
</style>
