<template>
  <el-dropdown>
    <div
      class="avatar"
      :style="{
        0: 'background: #3473E6;',
        1: 'background: #5DD5C8;',
        2: 'background: #1B335D;'
      }[titleType]">
        {{
          {
            0: String(userInfo.username).charAt(0),
            1: '科',
            2: '管'
          }[titleType]
        }}
      </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item @click.native="exit">退出登录</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import cookie from '@/plugins/cookie'
import globalVar from '@/configs/globalVar'

export default {
  props: {
    // 0为用户端，1为企业端，2为管理员端
    titleType: {
      type: Number,
      default: 0
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.userInfo
    }
  },
  created () {
    this.getUserInfo()
  },
  methods: {
    // 退出登录
    exit () {
      cookie.remove(globalVar.TOKEN)
      localStorage.clear()
      // 判断是否是管理员
      if (this.titleType === 2) {
        this.$router.push('/admin-login')
      } else {
        this.$router.push('/login')
      }
    },
    getUserInfo () {
      if (this.titleType !== 2) {
        this.$store.dispatch('getUserInfo')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 30px;
  text-align: center;
  line-height: 32px;
  font-size: 15px;
  color: white;
}
</style>
