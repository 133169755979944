<template>
  <div class="task_hall_company">
    <el-container>
      <el-header>
        <nav-header
          :titleType="titleType"
          :haveDot="false">
        </nav-header>
      </el-header>
      <el-container style="height: calc(100% - 60px);">
        <el-aside width="210px" class="aside_container">
          <nav-aside :menuList="menuList"></nav-aside>
        </el-aside>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import NavHeader from '@/components/layout/nav-header/index.vue'
import NavAside from '@/components/layout/nav-aside/index.vue'
export default {
  data () {
    return {
      menuList: [
        { path: '/admin/manager', title: '任务管理', icon: 'iconfont icon-renwu' },
        { path: '/admin/complains', title: '申诉处理', icon: 'iconfont icon-geren' },
        { path: '/admin/withdraw', title: '提现/充值', icon: 'iconfont icon-shensu' },
        {
          title: '账户管理',
          icon: 'iconfont icon-zhifu',
          children: [
            { path: '/admin/account', title: '账号管理' },
            { path: '/admin/certification', title: '企业认证审核' }
          ]
        }
      ],
      titleType: 2
    }
  },
  components: {
    NavHeader,
    NavAside
  }
}
</script>

<style lang="scss">
.task_hall_company {
  height: 100%;
  .el-main {
    background-color: #F3F3F3;
  }
}
</style>
