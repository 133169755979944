<template>
  <div class="nav_header_container">
    <div class="nav_header_left">
      <i
        style="font-size: 24px; color: #186DEC;"
        class="iconfont icon-logo">
      </i>
      <div class="title">任务悬赏平台</div>
      <div class="cutLine"></div>
      <div class="title_type" v-if="titleType === 0">用户端</div>
      <div class="title_type" v-else-if="titleType === 1">企业端</div>
      <div class="title_type" v-else>后台管理端</div>
    </div>
    <div class="nav_header_right">
      <!-- 消息提示 -->
      <message-alert
        v-if="haveDot">
      </message-alert>
      <!-- 退出登录 -->
      <nav-avatar
        :titleType="titleType">
      </nav-avatar>
    </div>
  </div>
</template>

<script>
import NavAvatar from './components/nav-avatar.vue'
import MessageAlert from './components/message-alert.vue'

export default {
  components: {
    NavAvatar,
    MessageAlert
  },
  props: {
    // 0为用户端，1为企业端，3为管理员端
    titleType: {
      type: Number,
      default: 0
    },
    haveDot: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.nav_header_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .nav_header_left {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
    }
    .cutLine {
      width: 1px;
      height: 20px;
      background: #DCDFE6;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      margin: 0 10px;
    }
    .title_color {
      font-size: 18px;
      font-weight: bold;
      margin: 0 10px;
      color: #3473E6;
    }
    .title_type {
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #606266;
      margin-left: 10px;
    }
  }
  .nav_header_right {
    display: flex;
    align-items: center;
  }
}
</style>
