<template>
  <el-dropdown>
    <span class="el-dropdown-link">
      <el-badge :is-dot="isDot">
        <i class="iconfont icon-xiaoxi"></i>
      </el-badge>
    </span>
    <el-dropdown-menu
      slot="dropdown"
      class="drop_down_menu">
      <div class="message_header">
        <el-button
          type="text"
          @click="handleAllRead">
          全部已读
        </el-button>
      </div>
      <div class="message_container">
        <div class="message_item"
          v-for="item in notifyList"
          :key="item.id"
          @click="handleOneRead(item.id)">
          <div class="message_icon">
            <div class="el-icon-coin"></div>
          </div>
          <div class="message_main_info">
            <div :style="{color:(item.status === 0 ? '#3473E6':'')}">
              {{item.title}}
            </div>
            <div class="description">{{item.contentText}}</div>
            <div class="time">{{item.createdAt}}</div>
          </div>
        </div>
      </div>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import api from '@/api/index'
export default {
  data () {
    return {
      notifyList: [],
      isDot: false
    }
  },
  created () {
    this.getNotify()
  },
  methods: {
    getNotify () {
      api.getNotify().then(res => {
        if (res.data.code === 0) {
          this.notifyList = res.data.data.list
          this.isDot = this.searchMessage()
        }
      })
    },
    // 处理消息单个点击后变为已读状态
    handleOneRead (id) {
      api.oneRead(id).then(res => {
        if (res.data.code === 0) {
          this.getNotify()
        } else {
          this.$message.error('操作失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      })
    },
    // 处理消息全部已读
    handleAllRead () {
      api.addRead().then(res => {
        if (res.data.code === 0) {
          this.getNotify()
          this.isDot = false
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    // 查询消息是否全部已读
    searchMessage () {
      return this.notifyList.some(item => {
        return item.status === 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.drop_down_menu {
  width: 400px;
  height: 520px;
  padding: 0 20px;
  overflow-y: auto;
  overflow-x: hidden;
  .message_header {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #E4E7ED;
    height: 50px;
  }
  .message_container {
    .message_item {
      display: flex;
      padding: 10px;
      cursor: pointer;
      .message_icon {
        width: 40px;
        height: 40px;
        min-width: 40px;
        background: #D6E3FA;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      .message_main_info {
        .title {
          font-size: 16px;
          font-weight: bold;
        }
        .description {
          font-size: 14px;
          color: #606266;
          margin: 5px 0;
        }
        .time {
          font-size: 12px;
          color: #909399;
        }
      }
    }
    .message_item:hover {
      background-color: #F5F7FA;
      border-radius: 6px;
    }
  }
}
</style>
